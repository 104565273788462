import model from './model';

import { DEFAULT_LOCALE } from '../../utils/constants';
import {
  getSlotsAvailability,
  updateWidgetInfo,
  populateServiceData,
  populateWidgetData,
  prepareRepeaterData,
  queryFirstService,
  getServiceV2ById,
  getTimezoneFromBusinessData,
  calculateNumberOfSlotsFromMainRepeaterData,
  translateWidgetElements,
} from './helpers';
import {
  biReportWidgetLoaded,
  biReportTimePickerLoaded,
  biReportBookingsUouSeeMoreDatesTimesClick,
} from '../../biEvents';
import { components } from './components';
import { images } from './images';

let allServices: any[] = [];
let availability;
let preferredTimezone: string | undefined = 'America/New_York';
let siteLocale: string | undefined = DEFAULT_LOCALE;
let businessTimezoneData: {
  businessTimezone: string | undefined;
  clientTimezone: string;
  userTimezonePreference: string;
} = {
  businessTimezone: 'America/New_York',
  clientTimezone: 'America/New_York',
  userTimezonePreference: 'business',
};
export default model.createController((controllerParams) => {
  let selectedService;
  const { $widget, flowAPI, $w, controllerConfig } = controllerParams;
  const t = flowAPI.translations.t;
  const site = controllerConfig?.wixCodeApi?.site;
  const biLogger = flowAPI.essentials.biLoggerFactory().logger();

  console.log('UOU | controllerParams:', controllerParams);
  const query = controllerParams?.controllerConfig?.wixCodeApi?.location?.query;
  console.log('UOU | query:', query);

  const env = flowAPI.environment;
  // Workaround for TB-9985
  if (
    query?.commonConfig !== undefined &&
    query.commonConfig.includes('studio')
  ) {
    env.isEditorX = true;
    env.isEditor = false;
  }
  console.log('UOU | env:', env);

  if (env.isClassicEditor || env.isEditor || env.isEditorX) {
    // console.log('UOU | Editor mode');
    $w(components.multiStateBox).changeState('main');
    $w(components.availabilityStateBox).changeState('service');
  } else if (env.isViewer && env.isSSR) {
    // console.log('UOU | Viewer SSR mode');
    $w(components.multiStateBox).changeState('main');
    $w(components.availabilityStateBox).changeState('loader');
  } else {
    // console.log('UOU | live mode, nothing to do.');
  }
  $widget.onPropsChanged((oldProp, newProp) => {
    // console.log('UOU | All presets | oldProp:', oldProp);
    // console.log('UOU | All presets | newProp:', newProp);
    $w(components.serviceImage).imageShape = newProp.imageShape;
    $w(components.serviceImage).layout = newProp.layout;
    const updateService = oldProp.serviceId
      .toString()
      .includes(newProp.serviceId.toString())
      ? false
      : true;
    const updateLocation = oldProp.locationId
      .toString()
      .includes(newProp.locationId.toString())
      ? false
      : true;
    if (
      oldProp.layout !== newProp.layout ||
      oldProp.numberOfSlots !== newProp.numberOfSlots ||
      oldProp.mobileNumberOfSlots !== newProp.mobileNumberOfSlots ||
      !oldProp.serviceId.toString().includes(newProp.serviceId.toString()) ||
      !oldProp.locationId.toString().includes(newProp.locationId.toString())
    ) {
      // console.log('UOU | Need to updateService:', updateService);
      // console.log('UOU | Need to updateLocation:', updateLocation);
      updateWidgetData(newProp, updateService, updateLocation);
    }
  });

  const updateWidgetData = async (props, updateService, updateLocation) => {
    // console.log('UOU | updateWidgetData is running');
    if (updateService || updateLocation) {
      // console.log('UOU | Need to update service to:', props.serviceId);
      selectedService = await getServiceV2ById(flowAPI, props.serviceId);
    }
    // console.log('UOU | Updating widget to props:', props);
    // console.log('UOU | props.locationId:', props.locationId);

    if (
      (props.locationId.includes('CUSTOM') &&
        selectedService?.locations[0].type.includes('CUSTOM')) ||
      (props.locationId === 'BUSINESS' &&
        selectedService?.locations[0].type === 'BUSINESS')
    ) {
      // console.log('UOU | CUSTOM Location match!');
      updateWidgetInfo(
        $w,
        flowAPI,
        availability,
        businessTimezoneData,
        siteLocale,
        props,
        preferredTimezone,
        selectedService,
        biLogger,
      );
    } else if (
      selectedService?.locations[0].type === 'BUSINESS' &&
      props.locationId !== 'CUSTOM' &&
      selectedService?.locations.filter(
        (location) => location.business.id === props.locationId,
      ).length > 0
    ) {
      updateWidgetInfo(
        $w,
        flowAPI,
        availability,
        businessTimezoneData,
        siteLocale,
        props,
        preferredTimezone,
        selectedService,
        biLogger,
      );
    } else if (selectedService === undefined) {
      // console.log('UOU | dummy data state');
      // setDummyDataAvailability(props);
    } else {
      // console.log('UOU | locationId missmatch :(');
    }
    // console.log('UOU | Done Widget Data');
  };

  return {
    pageReady: async () => {
      translateWidgetElements($w, t);
      // siteLocale = capitalizeLastTwoLetters(site.regionalSettings);
      siteLocale = site.regionalSettings;
      if (!env.isSSR) {
        businessTimezoneData = await getTimezoneFromBusinessData(
          controllerConfig.appParams.instance,
        );
        console.log('UOU | businessTimezoneData:', businessTimezoneData);
        preferredTimezone =
          businessTimezoneData.userTimezonePreference === 'business'
            ? businessTimezoneData.businessTimezone
            : businessTimezoneData.clientTimezone!;
      }

      // console.log('env:', env)
      if (env.isEditor) {
        $w(components.serviceImage).setImage(images.classic);
      } else if (env.isEditorX) {
        $w(components.serviceImage).setImage(images.studio);
      }

      const props = $widget.props;
      // console.log('UOU | pageReady props:', props);

      const serviceId: String = String(props?.serviceId);
      // console.log('pageReady serviceId:', serviceId);

      let mainRepeaterData: any[] = [];

      if (serviceId.includes('undefined')) {
        // console.log('UOU | No service defined by prop');
        allServices = await queryFirstService(flowAPI);
        // console.log('UOU | Found services:', allServices);
        if (allServices !== undefined && allServices.length === 0) {
          // console.log('UOU | No prop and no services');
          if (flowAPI.environment.isViewer) {
            $w(components.multiStateBox).changeState('noServices');
          }
        } else if (allServices !== undefined && allServices.length !== 0) {
          // // console.log(
          //   'UOU | No serviceId prop but services found, showing the first service',
          // );
          selectedService = allServices[0];
          // console.log('UOU | Setting selected service to:', selectedService);
          props.serviceId = selectedService.id;
          props.locationId = selectedService.locations[0].business
            ? selectedService.locations[0].business.id
            : selectedService.locations[0].type;
          // console.log('UOU | Updated props:', props);
          populateServiceData(
            $w,
            flowAPI,
            allServices[0],
            preferredTimezone,
            siteLocale,
            businessTimezoneData,
          );
          // Show availability based on first location

          availability = await getSlotsAvailability(
            flowAPI.httpClient,
            businessTimezoneData,
            props,
            preferredTimezone,
            selectedService.type === 'APPOINTMENT'
              ? selectedService.staffMemberIds.length
              : 1,
            flowAPI.environment.isMobile,
          );
          console.log('UOU | Availability:', availability);

          mainRepeaterData = prepareRepeaterData(
            availability,
            siteLocale,
            flowAPI.environment.isMobile
              ? props.mobileNumberOfSlots
              : props.numberOfSlots,
            preferredTimezone,
          );
          // console.log('UOU | mainRepeaterData:', mainRepeaterData);

          populateWidgetData(
            $w,
            flowAPI,
            allServices[0],
            mainRepeaterData,
            siteLocale,
            preferredTimezone,
            biLogger,
          );
        }
      } else {
        // console.log('UOU | Service defined by prop:', serviceId);
        // console.log('UOU | allServices:', allServices);
        selectedService = await getServiceV2ById(flowAPI, serviceId);
        // console.log('UOU | selectedService:', selectedService);
        if (selectedService.hidden) {
          // console.log('UOU | Found serviceId but no services');
          if (!env.isSSR && flowAPI.environment.isViewer) {
            $w(components.multiStateBox).changeState('noServices');
          }
          // Show dummy data - do nothing
        } else {
          // console.log('UOU | Found serviceId and found services');
          // Populate service
          populateServiceData(
            $w,
            flowAPI,
            selectedService,
            preferredTimezone,
            siteLocale,
            businessTimezoneData,
          );
          if (!env.isSSR) {
            availability = await getSlotsAvailability(
              flowAPI.httpClient,
              businessTimezoneData,
              props,
              preferredTimezone,
              selectedService.type === 'APPOINTMENT'
                ? selectedService.staffMemberIds.length
                : 1,
              flowAPI.environment.isMobile,
            );

            mainRepeaterData = prepareRepeaterData(
              availability,
              siteLocale,
              flowAPI.environment.isMobile
                ? props.mobileNumberOfSlots
                : props.numberOfSlots,
              preferredTimezone,
            );
            // console.log('UOU | mainRepeaterData:', mainRepeaterData);
          } else {
            $w(components.availabilityStateBox).changeState('loader');
          }
          populateWidgetData(
            $w,
            flowAPI,
            selectedService,
            mainRepeaterData,
            siteLocale,
            preferredTimezone,
            biLogger,
          );
        }
      }
      if (!env.isSSR) {
        $widget.fireEvent('widgetLoaded', {});
        biReportWidgetLoaded(biLogger, controllerParams.flowAPI.environment);
        biReportTimePickerLoaded(
          biLogger,
          calculateNumberOfSlotsFromMainRepeaterData(mainRepeaterData),
        );
      }

      $w(components.seeMoreDatesAndTimes).onClick(() => {
        // console.log('UOU | clicked: seeMoreDatesAndTimes');
        biReportBookingsUouSeeMoreDatesTimesClick(biLogger);
      });
    },
    exports: {},
  };
});

// This function changes 'en-us' to 'en-US'. Need to see if mandatory.
function capitalizeLastTwoLetters(str) {
  if (str.length < 2) {
    return str.toUpperCase();
  } // If the string has less than 2 characters, return the string in uppercase
  const lastTwo = str.slice(-2).toUpperCase(); // Get the last two characters and capitalize them
  return str.slice(0, -2) + lastTwo; // Concatenate the original string without the last two characters with the capitalized last two characters
}
