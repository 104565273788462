import model from './model';
import { components } from './components';

export default model.createController((controllerParams) => {
  const { $widget, $w } = controllerParams;

  const setTranslation = (string) => {
    // @ts-expect-error
    $w(components.badge).text = string;
  };

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {
      setTranslation,
    },
  };
});
